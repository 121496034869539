// MIS IMAGENES
import BannerImg from "~image/banner/banner.jpeg"
import RecorridoImg from "~image/recorrido/recorrido.png"
//

import LogoBlack from "~image/logo/park-blue.png"
import CBLogoBlack from "~image/logo/cb-logo-blue.png"
import LogoWhite from "~image/logo/park-white.png"
import CBLogoWhite from "~image/logo/cb-logo-white.png"
import counDownImage from "~image/mixed/pattern-img-1.png"
import l2BrandLogoLine1 from "~image/brand-logo/brand-line-1.png"
import l2BrandLogoLine2 from "~image/brand-logo/brand-line-2.png"
import l2BrandLogoLine3 from "~image/brand-logo/brand-line-3.png"
import l2BrandLogoLine4 from "~image/brand-logo/brand-line-4.png"
import l2BrandLogoLine5 from "~image/brand-logo/brand-line-5.png"
import l2BrandLogoLine6 from "~image/brand-logo/brand-line-6.png"
import l2BrandLogoLine7 from "~image/brand-logo/brand-line-7.png"
import l2BrandLogoLine8 from "~image/brand-logo/brand-line-8.png"
import l2BrandLogo1 from "~image/brand-logo/brand-logo-1.png"
import l2BrandLogo2 from "~image/brand-logo/brand-logo-2.png"
import l2BrandLogo3 from "~image/brand-logo/brand-logo-3.png"
import l2BrandLogo4 from "~image/brand-logo/brand-logo-4.png"
import l2BrandLogo5 from "~image/brand-logo/brand-logo-5.png"
import l2BrandLogo6 from "~image/brand-logo/brand-logo-6.png"
import l2BrandLogo7 from "~image/brand-logo/brand-logo-7.png"
import l2BrandLogo8 from "~image/brand-logo/brand-logo-8.png"
import l2BrandLogo9 from "~image/brand-logo/brand-logo-9.png"
import servicesIcon2 from "~image/marketing/archery-target.svg"
import servicesIcon1 from "~image/marketing/athletics.svg"
import ProMoShape from "~image/marketing/footer-shape.png"
import contentTwoDots from "~image/marketing/gray-dots.png"
import heroImage4 from "~image/marketing/hero-dots.png"
import contentOneMain from "~image/marketing/l1-contentOne-img-woman.png"
import contentOneShape1 from "~image/marketing/l1-contentOne-shape-1.png"
import contentOneShape2 from "~image/marketing/l1-contentOne-shape-2.png"
import contentTwoShape from "~image/marketing/l1-contentTwo-shape-1.png"
import featureShape2 from "~image/marketing/l1-full-circle-shape.png"
import featureShape1 from "~image/marketing/l1-half-circle-shape.png"
import heroImage1 from "~image/marketing/l1-hero-img-1.png"
import heroImage2 from "~image/marketing/l1-hero-img-2.png"
import heroImage3 from "~image/marketing/l1-hero-img-3.png"
import heroImageMain from "~image/marketing/l1-hero-img-ipad.png"
import heroImage5 from "~image/marketing/l1-hero-shape-1.png"
import heroImage6 from "~image/marketing/l1-hero-shape-2.png"
import heroImage7 from "~image/marketing/l1-hero-shape-3.png"
import servicesIcon3 from "~image/marketing/money-coins.svg"
import contentOneDots from "~image/marketing/purple-dots.png"
import testiMonialUserimg1 from "~image/marketing/user-1.png"
import testiMonialUserimg2 from "~image/marketing/user-2.png"
import testiMonialUserimg3 from "~image/marketing/user-3.png"
import featureTwoIcon1 from "~image/project-management/icon-grid.png"
import featureTwoIcon2 from "~image/project-management/icon-message.png"
import contentL2oneDots from "~image/project-management/l2-content-1-shape.png"
import contentTwol2Shape1 from "~image/project-management/l2-content-2-shape-2.png"
import contentTwol2Shape2 from "~image/project-management/l2-content-2-shape.png"
import contentL2oneImgMain from "~image/project-management/l2-content-img-1.png"
import contentL2TwoImgMain from "~image/project-management/l2-content-img-2.png"
import contentL2oneShape1 from "~image/project-management/l2-content-shape-2.png"
import l2CtaBg from "~image/project-management/l2-cta-mask.png"
import featureTwoMain from "~image/project-management/l2-feature-img-1.png"
import featureTwoShape1 from "~image/project-management/l2-feature-shape.png"
import heroTwoImageMain from "~image/project-management/l2-hero-image.png"
import heroTwoShape2 from "~image/project-management/l2-hero-shape-1.png"
import heroTwoDots from "~image/project-management/l2-hero-shape-2.png"
import heroTwoShape1 from "~image/project-management/l2-hero-shape.png"
import footer2Payment from "~image/project-management/payment-gatway.png"
import herol3Imgwoman from "~image/it-services/welcome-woman-l3.png"
import herol3Imgman from "~image/it-services/welcome-man-l3.png"
import herol3ImgShape1 from "~image/it-services/welcome-shape-l3.png"
import herol3ImgShape2 from "~image/it-services/welcome-shape-l3-2.png"
import l3serVicesShape from "~image/it-services/services-more-shape.png"
import l3featureImg from "~image/it-services/feature-l3-image.png"
import l3conTentOneImg from "~image/it-services/feature-l3-image.png"
import l3conTentTwoImg from "~image/it-services/content-l3-img-1.png"
import l3promoBg from "~image/it-services/promo-bg.png"
import l3testiMonialUserImg1 from "~image/it-services/user-circle-1.png"
import l3testiMonialUserImg2 from "~image/it-services/user-circle-2.png"
import l3testiMonialUserImg3 from "~image/it-services/user-circle-3.png"
import l3testiMonialUserImg4 from "~image/it-services/user-circle-4.png"
import l3testiMonialUserImg5 from "~image/it-services/user-circle-5.png"
import l3testiMonialUserImg6 from "~image/it-services/user-circle-6.png"
import footer3instaImg1 from "~image/it-services/insta-1.png"
import footer3instaImg2 from "~image/it-services/insta-2.png"
import footer3instaImg3 from "~image/it-services/insta-3.png"
import footer3instaImg4 from "~image/it-services/insta-4.png"
import footer3instaImg5 from "~image/it-services/insta-5.png"
import footer3instaImg6 from "~image/it-services/insta-6.png"
import herol4Img from "~image/home-services/hero-l4-image.png"
import herol4ImgOverlay from "~image/home-services/hero-overlay.png"
import aboutUsl4Img1 from "~image/home-services/about-us-image-l4-1.png"
import aboutUsl4Img2 from "~image/home-services/about-us-image-l4-2.png"
import l4whyChooseImg from "~image/home-services/content-img-l4-2.png"
import l4promoBg from "~image/home-services/promo-l4-img.png"
import testiMonialUserimg4 from "~image/home-services/user-img-1.png"
import l4MapImg from "~image/home-services/contact-map.png"
import herol5Img from "~image/home-agency/hero-l5-bg.jpg"
import herol5ImgOverlay from "~image/home-agency/hero-bg-overlay.png"
import videoContentImg from "~image/home-agency/about-us-l5-img.png"
import aboutUsl5Img1 from "~image/home-agency/content-1-l5-img.png"
import l4testiMonialUserimg1 from "~image/home-agency/customer-img-1.png"
import l4testiMonialUserimg2 from "~image/home-agency/customer-img-2.png"
import l4testiMonialUserimg3 from "~image/home-agency/customer-img-3.png"
import l5promoBg from "~image/home-agency/promo-l5-img.png"
import herol6Img from "~image/home-digital-agency/hero-l7-bg.png"
import l6servicesIcon1 from "~image/home-digital-agency/services-icon-1.png"
import l6servicesIcon2 from "~image/home-digital-agency/services-icon-2.png"
import l6servicesIcon3 from "~image/home-digital-agency/services-icon-3.png"
import l6videoContentImg from "~image/home-digital-agency/about-videos-img.png"
import aboutUsl6Img1 from "~image/home-digital-agency/content-l7-1.png"
import aboutUsl6Img2 from "~image/home-digital-agency/content-l7-2.png"
import contentOnel6Img1 from "~image/home-digital-agency/1.jpg"
import contentOnel6Img2 from "~image/home-digital-agency/2.jpg"
import l6teamImg1 from "~image/home-digital-agency/team-member-1.png"
import l6teamImg2 from "~image/home-digital-agency/team-member-2.png"
import l6teamImg3 from "~image/home-digital-agency/team-member-3.png"
import l6teamImg4 from "~image/home-digital-agency/team-member-4.png"
import l6teamImg5 from "~image/home-digital-agency/team-member-5.png"
import l6portFolioImg1 from "~image/portfolio/portfolio-l6-img-1.png"
import l6portFolioImg2 from "~image/portfolio/portfolio-l6-img-2.png"
import l6portFolioImg3 from "~image/portfolio/portfolio-img-1.png"
import herol7Img1 from "~image/home-app/hero-l8-1.png"
import herol7Img2 from "~image/home-app/hero-l8-2.png"
import ContenToneL7Img from "~image/home-app/about-mobile-img.png"
import ContenTtwoL7Img from "~image/home-app/content-mobile-img.png"
import ContenTtwoL7Shape from "~image/home-app/red-shape.png"
import l7promoImg from "~image/home-app/promo-l8-img.png"
import herol8Img from "~image/home-startup/hero-img.png"
import l8serVicesImg1 from "~image/home-startup/services-img-1.png"
import l8serVicesImg2 from "~image/home-startup/services-img-2.png"
import l8aboutUsimg from "~image/home-startup/about-us-img.png"
import l8portFolioImg1 from "~image/portfolio/l8-portfolio-img-1.png"
import l8portFolioImg2 from "~image/portfolio/l8-portfolio-img-2.jpg"
import l8portFolioImg3 from "~image/portfolio/l8-portfolio-img-3.jpg"
import l8portFolioImg4 from "~image/portfolio/l8-portfolio-img-4.jpg"
import l8portFolioImg5 from "~image/portfolio/l8-portfolio-img-5.jpg"
import blogThumbImg1 from "~image/home-startup/blogs-img-1.png"
import blogThumbImg2 from "~image/home-startup/blogs-img-2.png"
import blogThumbImg3 from "~image/home-startup/blogs-img-3.png"
import InnerPromoShape from "~image/mixed/inner-banner-shape.png"
import BlogsImg1 from "~image/blogs/blog-post-1.png"
import BlogsImg2 from "~image/blogs/blog-post-2.png"
import BlogsImg3 from "~image/blogs/blog-post-3.png"
import BlogsImg4 from "~image/blogs/blog-post-4.png"
import BlogsImg5 from "~image/blogs/blog-post-5.png"
import BlogsImg6 from "~image/blogs/blog-post-6.png"
import BlogsImg7 from "~image/blogs/blog-post-7.png"
import BlogsDetailsImg from "~image/blogs/blog-details-img-1.png"
import SiderbarAdsImg from "~image/mixed/ads-img.png"
import commentsUserImg1 from "~image/mixed/user-img-1.png"
import commentsUserImg2 from "~image/mixed/user-img-2.png"
import commentsUserImg3 from "~image/mixed/user-img-3.png"
import CareerPageVideobg from "~image/mixed/career-video-bg.png"
import SigninBgImg from "~image/accounts/sign-in.jpg"
import SignupBgImg from "~image/accounts/sign-up.jpg"
import ResetBgImg from "~image/accounts/reset-pass-img.jpg"
import PortFoliosImg1 from "~image/portfolio/portfolio-img-1.png"
import PortFoliosImg2 from "~image/portfolio/portfolio-img-2.png"
import PortFoliosImg3 from "~image/portfolio/portfolio-img-3.png"
import PortFoliosImg4 from "~image/portfolio/portfolio-img-4.png"
import PortFoliosImg5 from "~image/portfolio/portfolio-img-5.png"

import PortFoliosImg6 from "~image/portfolio/portfolio-mas-5.jpg"
import PortFoliosImg7 from "~image/portfolio/portfolio-mas-1.jpg"
import PortFoliosImg8 from "~image/portfolio/portfolio-mas-2.jpg"
import PortFoliosImg9 from "~image/portfolio/portfolio-mas-11.jpg"
import PortFoliosImg10 from "~image/portfolio/portfolio-mas-9.jpg"
import PortFoliosImg11 from "~image/portfolio/portfolio-mas-6.jpg"
import PortFoliosImg12 from "~image/portfolio/portfolio-mas-8.jpg"
import PortFoliosImg13 from "~image/portfolio/portfolio-mas-4.jpg"
import PortFoliosImg14 from "~image/portfolio/portfolio-mas-15.jpg"
import PortFoliosImg15 from "~image/portfolio/portfolio-mas-16.jpg"
import PortFoliosDetailsMain1 from "~image/portfolio/portfolio-details-left.jpg"
import errorImage from "~image/mixed/404.png"

const Images = {
  bannerImagen: {
    bannerImg: BannerImg,
  },
  recorridoImagen: {
    recorridoImg: RecorridoImg,
  },
  marketing: {
    heroImageMain: heroImageMain,
    heroImage1: heroImage1,
    heroImage2: heroImage2,
    heroImage3: heroImage3,
    heroImage4: heroImage4,
    heroImage5: heroImage5,
    heroImage6: heroImage6,
    heroImage7: heroImage7,
    servicesIcon1: servicesIcon1,
    servicesIcon2: servicesIcon2,
    servicesIcon3: servicesIcon3,
    featureShape1: featureShape1,
    featureShape2: featureShape2,
    contentOneMain: contentOneMain,
    contentOneShape1: contentOneShape1,
    contentOneShape2: contentOneShape2,
    contentOneDots: contentOneDots,
    contentTwoDots: contentTwoDots,
    contentTwoShape: contentTwoShape,
    testiMonialUserimg1: testiMonialUserimg1,
    testiMonialUserimg2: testiMonialUserimg2,
    testiMonialUserimg3: testiMonialUserimg3,
    ProMoShape: ProMoShape,
  },
  projectManagement: {
    heroTwoImageMain: heroTwoImageMain,
    heroTwoShape1: heroTwoShape1,
    heroTwoShape2: heroTwoShape2,
    heroTwoDots: heroTwoDots,
    featureTwoMain: featureTwoMain,
    featureTwoShape1: featureTwoShape1,
    featureTwoIcon1: featureTwoIcon1,
    featureTwoIcon2: featureTwoIcon2,
    contentOneImgMain: contentL2oneImgMain,
    contentOneShape1: contentL2oneShape1,
    contentOneDots: contentL2oneDots,
    contentTwoImgMain: contentL2TwoImgMain,
    contentTwoShape1: contentTwol2Shape1,
    contentTwoShape2: contentTwol2Shape2,
    BrandLogo1: l2BrandLogo1,
    BrandLogo2: l2BrandLogo2,
    BrandLogo3: l2BrandLogo3,
    BrandLogo4: l2BrandLogo4,
    BrandLogo5: l2BrandLogo5,
    BrandLogo6: l2BrandLogo6,
    BrandLogo7: l2BrandLogo7,
    BrandLogo8: l2BrandLogo8,
    BrandLogo9: l2BrandLogo9,
    BrandLogoLine1: l2BrandLogoLine1,
    BrandLogoLine2: l2BrandLogoLine2,
    BrandLogoLine3: l2BrandLogoLine3,
    BrandLogoLine4: l2BrandLogoLine4,
    BrandLogoLine5: l2BrandLogoLine5,
    BrandLogoLine6: l2BrandLogoLine6,
    BrandLogoLine7: l2BrandLogoLine7,
    BrandLogoLine8: l2BrandLogoLine8,
    CtaBg: l2CtaBg,
  },
  footerTwo: {
    PaymentImg: footer2Payment,
  },
  itServices: {
    heroImgwoman: herol3Imgwoman,
    heroImgman: herol3Imgman,
    heroImgShape1: herol3ImgShape1,
    heroImgShape2: herol3ImgShape2,
    serVicesShape: l3serVicesShape,
    featureImg: l3featureImg,
    conTentOneImg: l3conTentOneImg,
    conTentTwoImg: l3conTentTwoImg,
    promoBg: l3promoBg,
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
  },
  footerThree: {
    instaImg1: footer3instaImg1,
    instaImg2: footer3instaImg2,
    instaImg3: footer3instaImg3,
    instaImg4: footer3instaImg4,
    instaImg5: footer3instaImg5,
    instaImg6: footer3instaImg6,
  },
  Services: {
    heroImg: herol4Img,
    heroImgOverlay: herol4ImgOverlay,
    aboutUsImg1: aboutUsl4Img1,
    aboutUsImg2: aboutUsl4Img2,
    whyChooseImg: l4whyChooseImg,
    promoBg: l4promoBg,
    testiMonialUserimg1: testiMonialUserimg1,
    testiMonialUserimg2: testiMonialUserimg2,
    testiMonialUserimg3: testiMonialUserimg3,
    testiMonialUserimg4: testiMonialUserimg4,
    mapImg: l4MapImg,
  },
  agency: {
    heroImg: herol5Img,
    heroImgOverlay: herol5ImgOverlay,
    videoContentImg: videoContentImg,
    aboutUsImg1: aboutUsl5Img1,
    testiMonialUserimg1: l4testiMonialUserimg1,
    testiMonialUserimg2: l4testiMonialUserimg2,
    testiMonialUserimg3: l4testiMonialUserimg3,
    promoBg: l5promoBg,
  },
  DigiTalagency: {
    heroImg: herol6Img,
    servicesIcon1: l6servicesIcon1,
    servicesIcon2: l6servicesIcon2,
    servicesIcon3: l6servicesIcon3,
    videoContentImg: l6videoContentImg,
    aboutUsImg1: aboutUsl6Img1,
    aboutUsImg2: aboutUsl6Img2,
    contentOneImg1: contentOnel6Img1,
    contentOneImg2: contentOnel6Img2,
    teamImg1: l6teamImg1,
    teamImg2: l6teamImg2,
    teamImg3: l6teamImg3,
    teamImg4: l6teamImg4,
    teamImg5: l6teamImg5,
    promoBg: l5promoBg,
    portFolioImg1: l6portFolioImg1,
    portFolioImg2: l6portFolioImg2,
    portFolioImg3: l6portFolioImg3,
  },
  HomeApp: {
    heroImg1: herol7Img1,
    heroImg2: herol7Img2,
    ContenToneImg1: ContenToneL7Img,
    ContenTtwoImg: ContenTtwoL7Img,
    ContenTtwoShape: ContenTtwoL7Shape,
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    promoImg: l7promoImg,
  },
  HomeApp: {
    heroImg1: herol7Img1,
    heroImg2: herol7Img2,
    ContenToneImg1: ContenToneL7Img,
    ContenTtwoImg: ContenTtwoL7Img,
    ContenTtwoShape: ContenTtwoL7Shape,
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    promoImg: l7promoImg,
  },
  HomeStartup: {
    heroImg: herol8Img,
    serVicesImg1: l8serVicesImg1,
    serVicesImg2: l8serVicesImg2,
    aboutUsimg: l8aboutUsimg,
    portFolioImg1: l8portFolioImg1,
    portFolioImg2: l8portFolioImg2,
    portFolioImg3: l8portFolioImg3,
    portFolioImg4: l8portFolioImg4,
    portFolioImg5: l8portFolioImg5,
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    blogThumbImg1: blogThumbImg1,
    blogThumbImg2: blogThumbImg2,
    blogThumbImg3: blogThumbImg3,
  },
  PageAboutUs: {
    videoContentImg: videoContentImg,
    conTentOneImg: l3conTentOneImg,
    aboutUsImg1: aboutUsl5Img1,
    teamImg1: l6teamImg1,
    teamImg2: l6teamImg2,
    teamImg3: l6teamImg3,
    teamImg4: l6teamImg4,
    teamImg5: l6teamImg5,
    InnerPromoShape: InnerPromoShape,
  },

  pageTitleShape: InnerPromoShape,

  BlogsPage: {
    BlogsImg1: BlogsImg1,
    BlogsImg2: BlogsImg2,
    BlogsImg3: BlogsImg3,
    BlogsImg4: BlogsImg4,
    BlogsImg5: BlogsImg5,
    BlogsImg6: BlogsImg6,
    BlogsImg7: BlogsImg7,
  },

  CommentsSections: {
    commentsUserImg1: commentsUserImg1,
    commentsUserImg2: commentsUserImg2,
    commentsUserImg3: commentsUserImg3,
  },

  PricingTable1: {
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    testiMonialUserImg4: l3testiMonialUserImg4,
    testiMonialUserImg5: l3testiMonialUserImg5,
    testiMonialUserImg6: l3testiMonialUserImg6,
  },
  TestiMOnial1: {
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    testiMonialUserImg4: l3testiMonialUserImg4,
    testiMonialUserImg5: l3testiMonialUserImg5,
    testiMonialUserImg6: l3testiMonialUserImg6,
  },

  TestiMOnial2: {
    testiMonialUserImg1: l3testiMonialUserImg1,
    testiMonialUserImg2: l3testiMonialUserImg2,
    testiMonialUserImg3: l3testiMonialUserImg3,
    testiMonialUserImg4: l3testiMonialUserImg4,
    testiMonialUserImg5: l3testiMonialUserImg5,
    testiMonialUserImg6: l3testiMonialUserImg6,
  },

  PortFolioPage: {
    PortFoliosImg1: PortFoliosImg1,
    PortFoliosImg2: PortFoliosImg2,
    PortFoliosImg3: PortFoliosImg3,
    PortFoliosImg4: PortFoliosImg4,
    PortFoliosImg5: PortFoliosImg5,
  },
  PortFolioMas4: {
    PortFoliosImg1: PortFoliosImg6,
    PortFoliosImg2: PortFoliosImg7,
    PortFoliosImg3: PortFoliosImg8,
    PortFoliosImg4: PortFoliosImg9,
    PortFoliosImg5: PortFoliosImg10,
    PortFoliosImg6: PortFoliosImg11,
    PortFoliosImg7: PortFoliosImg12,
    PortFoliosImg8: PortFoliosImg13,
    PortFoliosImg9: PortFoliosImg14,
    PortFoliosImg10: PortFoliosImg15,
  },
  PortFolioDetails1: {
    PortFoliosDetailsMain: PortFoliosDetailsMain1,
    PortFoliosImg1: PortFoliosImg1,
    PortFoliosImg2: PortFoliosImg2,
    PortFoliosImg3: PortFoliosImg3,
    PortFoliosImg4: PortFoliosImg4,
    PortFoliosImg5: PortFoliosImg5,
  },
  FooterOne: {
    LogoWhite: LogoWhite,
  },
  FooterFourDark: {
    LogoWhite: LogoWhite,
  },
  FooterSix: {
    LogoBlack: LogoBlack,
  },

  BlogsDetailsImg: BlogsDetailsImg,
  SiderbarAdsImg: SiderbarAdsImg,
  CareerPageVideobg: CareerPageVideobg,
  SigninBgImg: SigninBgImg,
  SignupBgImg: SignupBgImg,
  ResetBgImg: ResetBgImg,
  errorImage: errorImage,
  CountdownBgImg: counDownImage,
}

export default Images
